import React from "react";
import "./Style.css";
import { OfferYourSpaceForm } from "../../Layout/OfferYourSpaceForm/OfferYourSpaceForm";

export const OfferYourSpace = () => {
  return (
    <div>
      <h1>PAGINA DE OFERTAR ANUNCIO</h1>
      <h2>FORMULARIO DE REGISTRO PARA OFERTAR AUNCIOS</h2>
      <OfferYourSpaceForm/>
    </div>
  );
};
